<template>
  <div class="small ml-0 pl-md-0 pr-2 p-md-0" id="googleTranslate">
    <div class="h-100 justify-content-center align-items-center p-2" v-show="displaySpinner">
      <b-spinner small label="Small Spinner"/>
    </div>
    <select class="p-2 google-translate w-100 h-100" v-show="!displaySpinner" @change="getLocale($event)">
      <option value="">{{$t('select_language')}}</option>
      <option value="" v-if="localPicked !== null">{{$t('original_wording')}}</option>
      <option value="af">Afrikaans</option>
      <option value="sq">Shqip</option>
      <option value="am">አማርኛ</option>
      <option value="ar">العربية</option>
      <option value="hy">Հայերեն</option>
      <option value="az">azərbaycan dili</option>
      <option value="eu">euskara, euskera</option>
      <option value="be">беларуская мова</option>
      <option value="bn">বাংলা</option>
      <option value="bs">bosanski jezik</option>
      <option value="bg">български език</option>
      <option value="ca">català, valencià</option>
      <option value="ceb">Cebuano</option>
      <option value="ny">chiCheŵa, chinyanja</option>
      <option value="zh-CN">简体中文）</option>
      <option value="zh-TW">中國傳統的）</option>
      <option value="co">corsu, lingua corsa</option>
      <option value="hr">hrvatski jezik</option>
      <option value="cs">čeština, český jazyk</option>
      <option value="da">dansk</option>
      <option value="en">English</option>
      <option value="nl">Nederlands, Vlaams</option>
      <option value="eo">Esperanto</option>
      <option value="et">eesti, eesti keel</option>
      <option value="tl">Wikang Tagalog</option>
      <option value="fi">suomi, suomen kieli</option>
      <option value="fr">français, langue française</option>
      <option value="fy">Frysk</option>
      <option value="gl">Galego</option>
      <option value="ka">ქართული</option>
      <option value="de">Deutsch</option>
      <option value="el">ελληνικά</option>
      <option value="gu">ગુજરાતી</option>
      <option value="ht">Kreyòl ayisyen</option>
      <option value="ha">(Hausa) هَوُسَ</option>
      <option value="haw">Hawaiian</option>
      <option value="he">עברית</option>
      <option value="hi">हिन्दी, हिंदी</option>
      <option value="hmn">Hmong</option>
      <option value="hu">magyar</option>
      <option value="is">Íslenska</option>
      <option value="ig">Asụsụ Igbo</option>
      <option value="id">Bahasa Indonesia</option>
      <option value="ga">Gaeilge</option>
      <option value="it">Italiano</option>
      <option value="ja">日本語 (にほんご)</option>
      <option value="jw">ꦧꦱꦗꦮ, Basa Jawa</option>
      <option value="kn">ಕನ್ನಡ</option>
      <option value="kk">қазақ тілі</option>
      <option value="km">ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ</option>
      <option value="ko">한국어</option>
      <option value="ku">Kurdî, کوردی‎</option>
      <option value="ky">Кыргызча, Кыргыз тили</option>
      <option value="lo">ພາສາລາວ</option>
      <option value="la">latine, lingua latina</option>
      <option value="lv">latviešu valoda</option>
      <option value="lt">lietuvių kalba</option>
      <option value="lb">Lëtzebuergesch</option>
      <option value="mk">македонски јазик</option>
      <option value="mg">fiteny malagasy</option>
      <option value="ms">Bahasa Melayu, بهاس ملايو‎</option>
      <option value="ml">മലയാളം</option>
      <option value="mt">Malti</option>
      <option value="mi">te reo Māori</option>
      <option value="mr">मराठी</option>
      <option value="mn">Монгол хэл</option>
      <option value="my">ဗမာစာ</option>
      <option value="ne">नेपाली</option>
      <option value="no">Norsk</option>
      <option value="ps">Pashto</option>
      <option value="fa">فارسی</option>
      <option value="pl">język polski, polszczyzna</option>
      <option value="pt">Português</option>
      <option value="pa">ਪੰਜਾਬੀ, پنجابی‎</option>
      <option value="ro">Română</option>
      <option value="ru">русский</option>
      <option value="sm">gagana fa'a Samoa</option>
      <option value="gd">Gàidhlig</option>
      <option value="sr">српски језик</option>
      <option value="st">Sesotho</option>
      <option value="sn">chiShona</option>
      <option value="sd">सिन्धी, سنڌي، سندھی‎</option>
      <option value="si">සිංහල</option>
      <option value="sk">Slovenčina, Slovenský Jazyk</option>
      <option value="sl">Slovenski Jezik, Slovenščina</option>
      <option value="so">Soomaaliga, af Soomaali</option>
      <option value="es">Español</option>
      <option value="su">Basa Sunda</option>
      <option value="sw">Kiswahili</option>
      <option value="sv">Svenska</option>
      <option value="tg">тоҷикӣ, toçikī, تاجیکی‎</option>
      <option value="ta">தமிழ்</option>
      <option value="te">తెలుగు</option>
      <option value="th">ไทย</option>
      <option value="tr">Türkçe</option>
      <option value="uk">Українська</option>
      <option value="ur">اردو</option>
      <option value="uz">Oʻzbek, Ўзбек, أۇزبېك‎</option>
      <option value="vi">Tiếng Việt</option>
      <option value="cy">Cymraeg</option>
      <option value="xh">isiXhosa</option>
      <option value="yi">ייִדיש</option>
      <option value="yo">Yorùbá</option>
      <option value="zu">isiZulu</option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'GoogleTranslate',
  props: {
    displayOriginal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      localPicked: null,
      displaySpinner: false
    }
  },
  methods: {
    showSpinner (active) {
      this.displaySpinner = active
    },
    getLocale (event) {
      if (this.displayOriginal) {
        this.localPicked = null
        if (event.target.value !== '') {
          this.localPicked = event.target.value
        }
      }
      this.$emit('translate', event)
    }
  }
}
</script>
<style lang="scss">
  .google-translate {
    border: 1px solid $grey-border-and-text;
    border-radius: 2px;
    width: 100%;
    max-height: 35px;
  }

  #googleTranslate {
    width: auto;
    flex-grow: 1;
    position: relative;
    &::before {
      background: none;
    }
  }

  @include xs-up {
    #googleTranslate {
      padding-left: 3rem;
      &::before {
        content: '';
        position: absolute;
        width: 30px;
        height: 30px;
        background: url('~@/assets/google-translate-logo.png');
        left: 7px;
        top: 1px;
        background-position: center;
        background-size: contain;
      }
    }
  }

  @include md-up {
    #googleTranslate {
      width: 82%;
      position: relative;
      flex-grow: 0;

      &::before {
        left: -35px
      }
    }
  }
</style>
